import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.scss';
import 'focus-visible/dist/focus-visible.min.js'
import { Route, Switch } from 'react-router-dom';
import asyncComponent from './AsyncComponent';
import withTracker from './HOCs/withTracker';
import ReactGA from 'react-ga';
const AsyncHomePage = asyncComponent(() => import('./HomePage'));
const APIPage = asyncComponent(() => import('./APIPage'));
const GettingStarted = asyncComponent(() => import('./GettingStarted'));
const FAQs = asyncComponent(() => import('./FAQs'));
const BDD = asyncComponent(() => import('./BDD'));
const Guide = asyncComponent(() => import('./Guide'));
const StepDefs = asyncComponent(() => import('./StepDefsPage'));
const MobileStepDefs = asyncComponent(() => import('./MobileStepDefsPage'));
const StepDefsPremium = asyncComponent(() => import('./StepDefsPremiumPage'));
const PrivacyPolicy = asyncComponent(() => import('./PrivacyPolicy'));
// const AsyncWebComponentsPage = asyncComponent(() => import('./WebComponentsPage'));
// const AsyncSimplePage = asyncComponent(() => import('./SimplePage'));

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

document.body.addEventListener('click', (ev) => {
  if (ev.target.classList.contains('copy-code-btn')) {
    const el = ev.target;
    el.innerHTML = 'Copied &#x2713';
    const text = el.parentElement.querySelector('.js-to-copy').innerText;
    copyToClipboard(text);
    setTimeout(() => { el.innerHTML = 'Copy' }, 5000);
  }
});

ReactGA.initialize('UA-2348928-17');

const Routes = ({ childProps }) =>
  <Switch>
    <Route path="/" exact component={withTracker(AsyncHomePage)} props={childProps} />
    <Route path="/api" exact component={withTracker(APIPage)} props={childProps} />
    <Route path="/getting-started" exact component={withTracker(GettingStarted)} props={childProps} />
    <Route path="/faqs" exact component={withTracker(FAQs)} props={childProps} />
    <Route path="/bdd" exact component={withTracker(BDD)} props={childProps} />
    <Route path="/guide" exact component={withTracker(Guide)} props={childProps} />
    <Route path="/privacy-policy" exact component={withTracker(PrivacyPolicy)} props={childProps} />
    <Route path="/mobile-step-definitions" exact component={withTracker(MobileStepDefs)} props={childProps} />
    <Route path="/step-definitions" exact component={withTracker(StepDefs)} props={childProps} />
    <Route path="/premium-step-definitions" exact component={withTracker(StepDefsPremium)} props={childProps} />
  </Switch>

ReactDOM.render(<Router><Routes /></Router>, document.getElementById('root'));
